<template>
  <div id="EnterpriseCertificationFrom" ref="enterpriseCertificationFrom">
    <template v-if="stateShow">
      <h1
        style="
          width: 70%;
          font-size: 40px;
          color: #ffffff;
          font-weight: bold;
          margin: auto;
          margin-bottom: 20px;
        "
      >
        {{
          auditState == 1
            ? "认证中, 请等待后台审核"
            : auditState == 2
            ? "恭喜您, 认证通过, 请进入展位编辑器定制您的展位."
            : "认证未通过"
        }}
      </h1>

      <template v-if="auditNotes">
        <div
          style="
            width: 50%;
            font-size: 40px;
            color: red;
            font-weight: bold;
            margin: 50px auto;
          "
        >
          {{ auditNotes }}
        </div>
      </template>

      <template v-if="auditState == 3">
        <a-button
          type="primary"
          size="large"
          style="width: 150px;font-size: 18px;margin-right: 20px;"
          @click="clickAuditState"
        >
          重新提交认证
        </a-button>
      </template>

      <!-- <template v-if="auditState != 3"> -->
        <a-button
          type="primary"
          size="large"
          style="width: 150px;font-size: 18px;"
          @click="clickBack"
        >
          返回
        </a-button>
      <!-- </template> -->
      

    </template>

    <template v-else>
      <a-form-model
        ref="authenticationForm"
        :model="authenticationForm"
        :rules="authenticationFormRules"
        :label-col="{ span: 6, color: '#ffffff' }"
        :wrapper-col="{ span: 12 }"
      >

        <!-- <a-form-model-item prop="showType" >
          <template v-slot:label>
            <span class="label-name">参展类型</span>
          </template>

          <a-row style="text-align: left;">

            <a-radio-group name="radioGroup" v-model="authenticationForm.showType">

              <a-radio :value="1" style="color: #ffffff;">
                展商
              </a-radio>
              <a-radio :value="2" style="color: #ffffff;">
                店铺
              </a-radio>

            </a-radio-group>

          </a-row>

        </a-form-model-item> -->


        <a-form-model-item prop="businessName">
          <template v-slot:label>
            <span class="label-name">企业名称</span>
          </template>

          <a-input
            :disabled="inputDisabled"
            placeholder="请输入企业名称"
            v-model="authenticationForm.businessName"
          />
        </a-form-model-item>

        <a-form-model-item prop="address">
          <template v-slot:label>
            <span class="label-name">所在地</span>
          </template>

          <a-input
            :disabled="inputDisabled"
            placeholder="请输入企业所在地"
            v-model="authenticationForm.address"
          />
        </a-form-model-item>

        <a-form-model-item prop="contactPerson">
          <template v-slot:label>
            <span class="label-name">联系人</span>
          </template>

          <a-input
            :disabled="inputDisabled"
            placeholder="请输入联系人姓名"
            v-model="authenticationForm.contactPerson"
          />
        </a-form-model-item>

        <a-form-model-item prop="phone">
          <template v-slot:label>
            <span class="label-name">联系电话</span>
          </template>

          <a-input
            :disabled="inputDisabled"
            placeholder="请输入联系电话"
            v-model="authenticationForm.phone"
          />
        </a-form-model-item>

        <a-form-model-item prop="EMail">
          <template v-slot:label>
            <span class="label-name">邮箱</span>
          </template>

          <a-input
            :disabled="inputDisabled"
            placeholder="请输入邮箱"
            v-model="authenticationForm.EMail"
          />
        </a-form-model-item>

        <a-form-model-item prop="certificateNo">
          <template v-slot:label>
            <span class="label-name">统一社会信用代码</span>
          </template>

          <a-input
            :disabled="inputDisabled"
            placeholder="请输入统一社会信用代码"
            v-model="authenticationForm.certificateNo"
          />
        </a-form-model-item>

        <a-form-model-item prop="businessLicense">
          <template v-slot:label>
            <span class="label-name">营业执照</span>
          </template>

          <a-upload
            :disabled="inputDisabled"
            accept=".jpg,.jpeg,.png"
            :file-list="authenticationForm.businessLicense"
            list-type="picture-card"
            @preview="businessLicensePreview"
            @change="businessLicenseChange"
            :remove="businessLicenseRemove"
            :beforeUpload="() => false"
          >
            <div
              v-if="authenticationForm.businessLicense.length < 1"
              style="
                width: 300px;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <img src="/bjq-add-02.png" />
            </div>
          </a-upload>

          <a-modal
            :visible="businessLicensePreviewVisible"
            :footer="null"
            @cancel="businessLicensePreviewCense"
          >
            <img
              alt="example"
              style="width: 100%"
              :src="authenticationPreview"
            />
          </a-modal>
        </a-form-model-item>

        <a-button
          type="primary"
          size="large"
          @click="authenticationFormSubmit"
          :loading="submitLoading"
        >
          提交
        </a-button>
      </a-form-model>
    </template>
  </div>
</template>

<script>
import { getBase64 } from "@/utils/util";

import qiniuUpload from "@/utils/qiniuUpload";

import { submissions } from "@/api/manage";

export default {
  props: {
    authenticationFormData: {
      type: Object,
      default: {}
    },
    showType: {
      type: Number,
      default: {}
    }
  },
  data() {
    return {
      authenticationForm: {
        businessName: "",
        address: "",
        contactPerson: "",
        phone: "",
        EMail: "",
        certificateNo: "",
        businessLicense: [],
        // showType: 1
      },
      businessLicensePreviewVisible: false,
      authenticationPreview: "",
      authenticationFormRules: {
        businessName: {
          required: true,
          message: "企业名称不能为空",
          trigger: "blur",
        },
        address: {
          required: true,
          message: "企业所在地不能为空",
          trigger: "blur",
        },
        contactPerson: {
          required: true,
          message: "联系人姓名不能为空",
          trigger: "blur",
        },
        phone: {
          required: true,
          validator: this.checkPhoneNumber,
          trigger: "blur",
        },
        EMail: { required: true, validator: this.checkEMail, trigger: "blur" },
        certificateNo: {
          required: true,
          validator: this.checCertificateNo,
          trigger: "blur",
        },
        businessLicense: [
          { required: true, message: "请选择营业执照", trigger: "change" },
        ],
        // showType: [
        //   { required: true, message: "参展类型", trigger: "change" },
        // ],
      },
      // 是否显示状态
      stateShow: false,
      // 审核状态：（0-未认证、1-认证中、2-认证通过、认证未通过）
      auditState: 0,
      // 审核拒绝理由
      auditNotes: "",
      submitLoading: false,
      // 内容是否可填
      inputDisabled: false
    };
  },
  // watch: {
  //   authenticationFormData: {
  //     handler: function (newData, oldData) {
  //       this.analysisAuthenticationFormData(newData);
  //     },
  //     deep: true,
  //   },
  // },
  created() {
    

    // console.log(this.authenticationFormData);
    if(this.authenticationFormData.state == 0 && this.authenticationFormData.show_type == 0) {

    } else {

      this.analysisAuthenticationFormData(this.authenticationFormData);

    }


  },
  methods: {
    analysisAuthenticationFormData(authenticationFormData) {

      // this.auditState = authenticationFormData.state;
      

      this.auditState = this.showType == 1 ? authenticationFormData.state : authenticationFormData.shop_state;

      if (authenticationFormData.state != 0) {
        this.authenticationForm = {
          businessName: authenticationFormData.name,
          address: authenticationFormData.address,
          contactPerson: authenticationFormData.contact,
          phone: authenticationFormData.tel,
          EMail: authenticationFormData.email,
          certificateNo: authenticationFormData.certificate_num,
          businessLicense: [
            {
              uid: "-1",
              name: "cover.png",
              status: "done",
              url: authenticationFormData.certificate_url,
            },
          ],
        };
      }

      if (this.auditState == 3) {
        // this.auditNotes = authenticationFormData.audit_notes;

        this.auditNotes = this.showType == 1 ? authenticationFormData.audit_notes : authenticationFormData.shop_notes;

      }

      // if (authenticationFormData.state != 0) {
      //   this.stateShow = true;
      // }

      // inputDisabled
      if(this.showType == 1) {

        if (authenticationFormData.state != 0) {
          this.stateShow = true;
        }

        if(authenticationFormData.shop_state != 0) {
          this.inputDisabled = true;
        } else {
          this.inputDisabled = false;
        }

      } else {

        if (authenticationFormData.shop_state != 0) {
          this.stateShow = true;
        }
        
        if(authenticationFormData.state != 0) {
          this.inputDisabled = true;
        } else {
          this.inputDisabled = false;
        }

      }

    },
    clickBack() {
      // console.log(this.$parent.authenticationType);
      this.$parent.authenticationType = "unselected";
      
      this.$parent.title = "认证审核";

      this.$parent.initData();

    },
    // 提交企业认证
    authenticationFormSubmit() {
      this.submitLoading = true;
      const that = this;
      this.$refs.authenticationForm.validate(async (valid) => {

        if (valid) {
          let token = localStorage.getItem("Access-Token");
          let param = {
            name: that.authenticationForm.businessName,
            type: 1,
            // business_type: values.industryField,
            business_type: 1,
            certificate_num: that.authenticationForm.certificateNo,
            certificate_type: 1,
            address: that.authenticationForm.address,
            contact: that.authenticationForm.contactPerson,
            email: that.authenticationForm.EMail,
            tel: that.authenticationForm.phone,
            certificate_url: [],
            // show_type: that.authenticationForm.showType.
            show_type: this.showType
          };

          // 判断图片
          if (
            !that.authenticationForm.businessLicense[0].url &&
            !that.authenticationForm.businessLicense[0].preview
          ) {
            // 需要七牛云上传
            console.log("上传", that.authenticationForm.businessLicense);
            param.certificate_url = await qiniuUpload(
              that.authenticationForm.businessLicense,
              (res) => {}
            );
          } else {
            // 不需要七牛云上传
            console.log("不需要上传");
            param.certificate_url = [
              that.authenticationForm.businessLicense[0].url,
            ];
          }
          submissions({
            material: param,
            token: token,
          })
            .then((res) => {
              if (res.code == 200) {
                that.$notification.open({
                  message: "温馨提示",
                  description: "提交成功",
                  icon: (
                    <a-icon
                      type="check-circle"
                      theme="twoTone"
                      two-tone-color="#52c41a"
                    />
                  ),
                });
                that.stateShow = true;
                that.auditState = 1;
                this.submitLoading = false;
              }
            })
            .catch((err) => {
              this.submitLoading = false;
            });
        }
      });
    },
    // 营业执照预览
    async businessLicensePreview(file) {
      this.businessLicensePreviewVisible = true;
      if (!file.url && !file.preview) {
        this.authenticationPreview = await getBase64(file.originFileObj);
      } else {
        this.authenticationPreview = file.url;
      }
    },
    // 选择图片
    businessLicenseChange({ fileList }) {
      this.authenticationForm.businessLicense = fileList;
    },
    // 删除图片
    businessLicenseRemove() {
      this.authenticationForm.businessLicense = [];
    },
    // 关闭图片预览
    businessLicensePreviewCense() {
      this.businessLicensePreviewVisible = false;
    },

    /**
     * 验证手机号码
     */
    checkPhoneNumber(rule, value, callback) {
      const reg = /^[0-9]*$/;

      if (!value) {
        callback("手机号码不能为空");
      } else if (!reg.test(value)) {
        callback("请输入正确的手机号码");
      } else {
        callback();
      }
    },
    /**
     * 邮箱验证
     */
    checkEMail(rule, value, callback) {
      const reg =
        /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;

      if (!value) {
        callback("邮箱不能为空");
      } else if (!reg.test(value)) {
        callback("请输入正确的邮箱地址");
      } else {
        callback();
      }
    },
    // 证件号校验
    checCertificateNo(rule, value, callback) {
      // const reg = /^[0-9]*$/;
      // const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/;
      const reg = /^([0-9]|[a-zA-Z])*$/;
      if (!value) {
        callback("统一社会信用代码不能为空");
      } else if (!reg.test(value)) {
        callback("请输入正确的统一社会信用代码");
      } else {
        callback();
      }
    },
    clickAuditState() {
      // console.log(this.auditState);
      if (this.auditState == 3) {

        this.stateShow = false;
        this.auditNotes = "";
        this.inputDisabled = false;

      } else {
        window.location.href = "http://www.baidu.com";
      }
    },
  },
};
</script>

<style scoped>
.label-name {
  color: #ffffff;
  font-size: 17px;
}

/* ::v-deep .ant-upload.ant-upload-select-picture-card {
  background-color: transparent;
  border: none;
  border-radius: 0;
}

::v-deep .ant-upload-list-picture-card .ant-upload-list-item {
  width: 302px;
  height: 166px;
}

::v-deep .ant-upload-picture-card-wrapper {
  height: 190px;
}

::v-deep .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  object-fit: contain;
} */
</style>