import axios from 'axios'
// import store from '@/store'
// import storage from 'store'
import notification from 'ant-design-vue/es/notification'
// import { VueAxios } from './axios'
// import { ACCESS_TOKEN } from '@/store/mutation-types'

// import { createSign, getQueryVariable } from "@/utils/util"
import { createSign } from "@/utils/util"

// import qs from 'qs'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 6000 // 请求超时时间
})
// headers: { 'Content-Type': 'application/json; charset=utf-8' }

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    // const data = error.response.data
    // // 从 localstorage 获取 token
    // const token = storage.get(ACCESS_TOKEN)
    // if (error.response.status === 403) {
    //   notification.error({
    //     message: 'Forbidden',
    //     description: data.message
    //   })
    // }
    // if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
    //   notification.error({
    //     message: 'Unauthorized',
    //     description: 'Authorization verification failed'
    //   })
    //   if (token) {
    //     store.dispatch('Logout').then(() => {
    //       setTimeout(() => {
    //         window.location.reload()
    //       }, 1500)
    //     })
    //   }
    // }
    
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = localStorage.getItem("Access-Token");
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改

  if(config.data) {

    // if(typeof config.data === 'string') {

    //   config.data = config.data + "&token=" +  token;

    // } else {

      config.data["program_name"] = "test";
      config.data["nonceStr"] = "jx3d";
      if(token) {
        config.data["token"] = token;
      }

      config.data["sign"] = createSign(config.data);

      // console.log(config);

      // if(config.headers["Content-Type"] === "application/x-www-form-urlencoded") {

      //   config.data = qs.stringify(config.data);

      // }


    // }
  } 
  // else if(config.data) {

  //   let data = getQueryVariable(config.data);
  //   console.log(getQueryVariable(config.data));


  // }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {

  if(response.status != 200) {

    // notification.error({
    //   message: "接口状态异常",
    //   description: `接口状态：${response.status}`
    // })

  }

  if(response.data.code && response.data.code != 200) {
    
    console.log(response);

    if(response.data.code == 1003) {
      localStorage.removeItem("Access-Token");
    }


    notification.error({
      message: "返回异常",
      description: response.data.msg
    })
  }
  
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    // Vue.use(VueAxios, request)
    Vue.use(request)

  }
}

export default request

export {
  // installer as VueAxios,
  request as axios
}
