<template>
  <a-form
    id="changePasswordForm"
    ref="changePasswordForm"
    :form="changePasswordForm"
    @submit="changePasswordFormSubmit"
    style="width: 50%"
  >
    <a-form-item>
      <a-input
        size="large"
        placeholder="请输入手机号码"
        v-decorator="[
          'phone',
          { rules: [{ required: true, validator: checkPhoneNumber }] },
        ]"
      >
        <a-icon slot="prefix" type="mobile" />

        <a-button
          slot="enterButton"
          type="primary"
          :disabled="getSmsBut.disabled"
          style="width: 112px"
        >
          {{ getSmsBut.text }}
        </a-button>
      </a-input>
    </a-form-item>
    <a-form-item>
      <a-input-search
        size="large"
        @search="getChangePasswordSms"
        placeholder="请输入短信验证码"
        v-decorator="[
          'verificationCode',
          { rules: [{ required: true, message: '短信验证码不能为空' }] },
        ]"
      >
        <a-icon slot="prefix" type="message" />

        <a-button
          slot="enterButton"
          type="primary"
          :disabled="getSmsBut.disabled"
          style="width: 112px"
        >
          {{ getSmsBut.text }}
        </a-button>
      </a-input-search>
    </a-form-item>

    <a-form-item>
      <a-input
        size="large"
        placeholder="设置新密码"
        v-decorator="[
          'password',
          { rules: [{ required: true, validator: checkPassword }] },
        ]"
      >
        <a-icon slot="prefix" type="lock" />
      </a-input>
    </a-form-item>

    <a-form-item>
      <a-button
        type="primary"
        html-type="submit"
        size="large"
        style="width: 100%"
      >
        提交
      </a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import { resetpassword } from "@/api/manage";
import md5 from "js-md5";

export default {
  data() {
    return {
      changePasswordForm: this.$form.createForm(this),
      getSmsBut: {
        text: "获取验证码",
        disabled: false,
      },
    };
  },
  methods: {
    changePasswordFormSubmit(e) {
      const that = this;
      e.preventDefault();
      this.changePasswordForm.validateFields((err, values) => {
        if (!err) {
          const password = md5(values.password);
          resetpassword({
            phone: values.phone,
            sms_code: values.verificationCode,
            newpassword: password,
          }).then((res) => {
            if (res.code == 200) {
              that.$notification.open({
                message: "温馨提示",
                description: "修改成功",
                icon: (
                  <a-icon
                    type="check-circle"
                    theme="twoTone"
                    two-tone-color="#52c41a"
                  />
                ),
              });

              this.$parent.loginModule = 1;
            }
          });
        }
      });
    },
    // 修改密码获取短信验证码
    getChangePasswordSms() {
      this.changePasswordForm.validateFields(["phone"], (err) => {
        if (!err) {
          const phone = this.changePasswordForm.getFieldValue("phone");
          this.$parent.getSms(phone, "resetpassword", this.getSmsBut);
        }
      });
    },
    /**
     * 验证手机号码
     */
    checkPhoneNumber(rule, value, callback) {
      return this.$parent.checkPhoneNumber(rule, value, callback);
    },
    /**
     * 验证密码
     */
    checkPassword(rule, value, callback) {
      return this.$parent.checkPassword(rule, value, callback);
    },
  },
};
</script>

<style scoped>
</style>