import Vue from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import router from './router'
import store from './store/'
import 'ant-design-vue/dist/antd.css';

import '@/assets/font/font.css';

Vue.config.productionTip = true

import "./permission";

Vue.use(Antd);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
