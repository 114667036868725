<template>
  <div id="registration">
    <div class="content">
      <div class="registration-module">
        <div class="title">
          <img src="/title.png" />
        </div>

        <h1 class="subheading">账号管理</h1>

        <template v-if="loginModule == 1">
          <login></login>
        </template>

        <template v-else-if="loginModule == 2">
          <registration-form></registration-form>
        </template>

        <template v-else-if="loginModule == 3">
          <change-password></change-password>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { SendLoginSms } from "@/api/manage";

import Login from "./model/Login.vue";
import RegistrationForm from "./model/registrationForm.vue";
import ChangePassword from "./model/ChangePassword.vue";

export default {
  components: {
    Login,
    RegistrationForm,
    ChangePassword,
  },
  data() {
    return {
      loginModule: 1
    };
  },
  created() {
    let geturl = window.location.href;
    let getqyinfo = geturl.split("?");
    if (getqyinfo.length > 1) {
      getqyinfo = getqyinfo[1];
      let getqys = getqyinfo.split("&")[0].split("=")[1];
      if (getqys == 1) {
        this.loginModule = 2;
      }
    }
  },
  methods: {
    /**
     * 发送短信+
     * app: 注册：sign, 登录：login 更改密码：resetpassword
     */
    getSms(phone, app, butState) {
      const that = this;
      if (!phone) {
        return;
      }
      let count = 60;

      (function smsInterval() {
        count--;
        if (count >= 0) {
          butState.text = count;
          if (!butState.disabled) {
            butState.disabled = true;
          }
        } else {
          butState.text = "获取验证码";
          butState.disabled = false;
          return;
        }

        setTimeout(smsInterval, 1000);
      })();

      SendLoginSms({
        phone: phone,
        app: app,
      }).then((res) => {
        if (res.code == 200) {
          that.$notification.open({
            message: "温馨提示",
            description: "短信已发送，请注意查看",
            icon: (
              <a-icon
                type="check-circle"
                theme="twoTone"
                two-tone-color="#52c41a"
              />
            ),
          });
        }
      });
    },
    /**
     * 验证手机号码
     */
    checkPhoneNumber(rule, value, callback) {
      const reg = /^1[3-9]\d{9}$/;

      if (!value) {
        callback("手机号码不能为空");
      } else if (!reg.test(value)) {
        callback("请输入正确的手机号码");
      } else {
        callback();
      }
    },
    /**
     * 验证密码
     */
    checkPassword(rule, value, callback) {
      const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,15}$/;

      if (!value) {
        callback("6~15位数字和字母组合，不能使用标点符号");
      } else if (!reg.test(value)) {
        callback("6~15位数字和字母组合，不能使用标点符号");
      } else {
        callback();
      }
    },
    /**
     * 二次输入密码验证
     */
    checkConfirmPassword(rule, value, password, callback) {
      if (!value) {
        callback("请确认密码");
      } else if (value !== password) {
        callback("两次输入密码不一致");
      } else {
        callback();
      }
    },
  },
};
</script>

<style scoped>
#registration {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("/public/BG1.jpg") 100% 100% no-repeat;
}

.title {
  width: 100%;
  margin: 1.5em 0;
}
.title img {
  width: 70%;
}
.subheading {
  color: #72d1fa;
  font-size: 25px;
  font-family: "pangmenzhengdao";
}
.content {
  width: 100%;
  display: flex;
  justify-content: center;
}
.card-content {
  width: 300px;
  display: flex;
}

.registration-module {
  width: 35%;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  background: url("/public/denglu.png") no-repeat;
  background-size: 100% 100%;
}

.agreement-visible {
  background: url("/public/denglu.png") no-repeat;
}
</style>