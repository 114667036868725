import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
// import user from './modules/user'

// default router permission control
// import permission from './modules/permission'

// dynamic router permission control (Experimental)
// import permission from './modules/async-router'
// import getters from './getters'
const getters = {
  token: state => state.token,
}

Vue.use(Vuex)

export default new Vuex.Store({
  // modules: {
  //   app
  // },
  state: {
    token: ""
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
  },
  actions: {},
  getters
})
