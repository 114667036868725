import request from '@/utils/request'
// import qs from 'qs'

// const api = {
//   user: '/user',
//   role: '/role',
//   service: '/service',
//   permission: '/permission',
//   permissionNoPager: '/permission/no-pager',
//   orgTree: '/org/tree'
// }

// export default api

/**
 * 获取短信验证码
 * @param {*} parameter { phone: 手机号码, app: 应用标识-> 注册：sign 登录：login}
 * @returns 
 */
export function SendLoginSms (parameter) {
  return request({
    url: "/account/user/SendLoginSms",
    method: "post",
    data: parameter
  })
}

/**
 * 注册
 * @param {*} parameter { phone: 手机号码, code: 验证码, password: 登录密码->md5加密 }
 * @returns 
 */
export function sign (parameter) {
  return request({
    url: "/account/booth/sign",
    method: "post",
    data: parameter
  })
}

/**
 * 提交认证
 * @param {*} parameter { token: , material: { 
 *                                             name: 企业名称,
 *                                             type: 类型（1-企业、2-个人
 *                                             business_type：业态,
 *                                             certificate_num：证件号,
 *                                             certificate_type：证件类型（1-营业执照、2-身份证（正反面）)
 *                                             address：地址
 *                                             contact：联系人
 *                                             phone：联系电话
 *                                             certificate_url：证件图片地址
 *                                             } }
 * @returns 
 */
export function submissions (parameter) {
  return request({
    url: "/booth/apply/submissions",
    method: "post",
    data: parameter
  })
}

/**
 * 提交认证测试接口
 * @param {*} parameter 
 * @returns 
 */
// export function submissions (parameter) {
//   return request({
//     url: "/booth/apply/submissionstest",
//     method: "post",
//     data: parameter
//   })
// }

/**
 * 登录
 * @param {*} parameter 
 * @returns 
 */
export function login (parameter) {
  return request({
    url: "/account/booth/login",
    method: "post",
    data: parameter
  })
}

/**
 * 展厅管理员信息获取
 * @param {*} parameter 
 * @returns 
 */
export function getuserinfo (parameter) {
  return request({
    url: "/account/booth/getuserinfo",
    method: "post",
    data: parameter
  })
}

/**
 * 更改密码
 * @param {*} parameter 
 * @returns 
 */
export function resetpassword (parameter) {
  return request({
    url: "/account/booth/resetpassword",
    method: "post",
    data: parameter
  })
}

/**
 * 获取七牛上传token
 * @param {*} parameter { path }
 * @returns { code, key, download_url}
 */
export function getQiniuUploadInfo(parameter) {
  return request({
    url: "/common/publicfunc/getQiniuUploadInfo",
    method: "post",
    data: parameter
  })
}

// https://mvs.jx3d.cn/common/publicfunc/geteditorurl
/**
 * 获取软件编辑器下载地址
 * @returns 
 */
export function geteditorurl() {
  return request({
    url: "https://mvs.jx3d.cn/common/publicfunc/geteditorurl",
    method: "get"
  })
}



