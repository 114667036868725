<template>
  <div id="PersonalCertificateFrom">
    <a-form
      :form="PersonalCertificateFrom"
      @submit="PersonalCertificateFromSubmit"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 12 }"
      :colon="false"
    >
      <a-form-item>
        <template v-slot:label>
          <span class="label-name">姓名</span>
        </template>

        <a-input
          placeholder="请输入姓名"
          v-decorator="[
            'userName',
            { rules: [{ required: true, message: '请输入姓名' }] },
          ]"
        />
      </a-form-item>

      <a-form-item>
        <template v-slot:label>
          <span class="label-name">地址</span>
        </template>

        <a-input
          placeholder="请输入地址"
          v-decorator="[
            'address',
            { rules: [{ required: true, message: '请输入地址' }] },
          ]"
        />
      </a-form-item>

      <a-form-item>
        <template v-slot:label>
          <span class="label-name">身份证号</span>
        </template>

        <a-input
          placeholder="请输入身份证号"
          v-decorator="[
            'IDNumber',
            { rules: [{ required: true, validator: checkIDNumber }] },
          ]"
        />
      </a-form-item>

      <a-form-item>
        <template v-slot:label>
          <span class="label-name">联系电话</span>
        </template>

        <a-input
          placeholder="请输入联系电话"
          v-decorator="[
            'phoneNumber',
            { rules: [{ required: true, validator: checkPhoneNumber }] },
          ]"
        />
      </a-form-item>

      <a-form-item>
        <template v-slot:label>
          <span class="label-name">邮箱</span>
        </template>

        <a-input
          placeholder="请输入邮箱"
          v-decorator="[
            'EMail',
            { rules: [{ required: true, validator: checkEMail }] },
          ]"
        />
      </a-form-item>

      <a-form-item style="color: red">
        <template v-slot:label>
          <span class="label-name">身份证</span>
        </template>

        <a-row :gutter="24">
          <a-col :xxl="{span: 12}" :xl="{span: 24}">
            <a-form-item>
              <a-upload
                list-type="picture-card"
                @preview="identityCardPreviewVisible = true"
                :remove="removeIdentityCardFront"
                :beforeUpload="identityCardFrontUpload"
                v-decorator="[
                  'identityCardFront',
                  {
                    rules: [{ required: true, message: '请上传身份证' }],
                    initialValue: identityCardFrontFileList,
                  },
                ]"
              >
                <template v-if="identityCardFrontFileList.length < 1">
                  <img src="/bjq-add-02.png" />
                  <div class="identity-card-hint">
                    身份证正面照片 (含个人信息的一面)
                  </div>
                </template>
              </a-upload>
            </a-form-item>
          </a-col>

          <a-col :xxl="{span: 12}" :xl="{span: 24}">
            <a-form-item>
              <a-upload
                list-type="picture-card"
                @preview="identityCardPreviewVisible = true"
                :remove="removeIdentityCardReverseSide"
                :beforeUpload="identityCardReverseSideUpload"
                v-decorator="[
                  'identityCardReverseSide',
                  {
                    rules: [{ required: true, message: '请上传身份证' }],
                    initialValue: identityCardReverseSideFileList,
                  },
                ]"
              >
                <template v-if="identityCardReverseSideFileList.length < 1">
                  <img src="/bjq-add-02.png" />

                  <div class="identity-card-hint">
                    身份证反面照片 (含发证机关的一面)
                  </div>
                </template>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>

        <a-modal
          :visible="identityCardPreviewVisible"
          :footer="null"
          @cancel="identityCardPreviewVisible = false"
        >
          <img
            alt="example"
            style="width: 100%"
            :src="identityCardPreviewBase64"
          />
        </a-modal>
      </a-form-item>

      <a-button type="primary" html-type="submit" size="large"> 提交 </a-button>
    </a-form>
  </div>
</template>

<script>
import { getBase64 } from "@/utils/util";
import { submissions } from "@/api/manage";

import qiniuUpload from "@/utils/qiniuUpload";

export default {
  data() {
    return {
      PersonalCertificateFrom: this.$form.createForm(this),
      identityCardFrontFileList: [],
      identityCardPreviewBase64: "",
      identityCardReverseSideFileList: [],
      identityCardPreviewVisible: false,
    };
  },
  methods: {
    PersonalCertificateFromSubmit(e) {
      const that = this;

      e.preventDefault();
      this.PersonalCertificateFrom.validateFields((err, values) => {
        if (!err) {
          console.log(values);

          const identityCard = [
            values.identityCardFront,
            values.identityCardReverseSide,
          ];

          qiniuUpload(identityCard, (res) => {
            console.log(res);
          }).then((imgList) => {
            console.log(imgList);

            submissions({
              material: {
                name: values.userName,
                type: 2,
                business_type: 0,
                certificate_num: values.certificateNo,
                certificate_type: 2,
                address: values.address,
                contact: values.userName,
                tel: values.phoneNumber,
                certificate_url: imgList,
              },
              token: "32d0d21f27751ce0",
            }).then((res) => {
              if (res.code == 200) {
                that.$notification.open({
                  message: "温馨提示",
                  description: "提交成功",
                  onClick: () => {},
                });
              }
            });
          });
        }
      });
    },
    checkEMail(rule, value, callback) {
      const reg =
        /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;

      if (!value) {
        callback("邮箱不能为空");
      } else if (!reg.test(value)) {
        callback("请输入正确的邮箱地址");
      } else {
        callback();
      }
    },
    checkIDNumber(rule, value, callback) {
      let IDRe18 =
        /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      let IDre15 =
        /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;

      if (!value) {
        callback("身份证号不能为空");
      } else if (!IDRe18.test(value) && !IDre15.test(value)) {
        callback("请输入正确的身份证号");
      } else {
        callback();
      }
    },
    checkPhoneNumber(rule, value, callback) {
      const reg = /^1[3-9]\d{9}$/;

      if (!value) {
        callback("手机号码不能为空");
      } else if (!reg.test(value)) {
        callback("请输入正确的手机号码");
      } else {
        callback();
      }
    },
    removeIdentityCardFront() {
      this.identityCardFrontFileList = [];
    },
    removeIdentityCardReverseSide() {
      this.identityCardReverseSideFileList = [];
    },
    identityCardFrontUpload(file) {
      const that = this;

      this.identityCardFrontFileList = [file];

      getBase64(file).then((Base64) => {
        that.identityCardPreviewBase64 = Base64;
      });

      return false;
    },
    identityCardReverseSideUpload(file) {
      const that = this;

      this.identityCardReverseSideFileList = [file];

      getBase64(file).then((Base64) => {
        that.identityCardPreviewBase64 = Base64;
      });

      return false;
    },
  },
};
</script>

<style scoped>
.label-name {
  color: #ffffff;
  font-size: 17px;
}
.identity-card-hint {
  color: #ffffff;
}
</style>