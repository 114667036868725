<template>
  <a-form
    id="registrationForm"
    class="registration-form"
    ref="registrationForm"
    :form="registrationForm"
    @submit="registrationFormSubmit"
    style="width: 50%"
  >
    <a-form-item>
      <a-input
        size="large"
        placeholder="请输入手机号码"
        v-decorator="[
          'phone',
          { rules: [{ required: true, validator: checkPhoneNumber }] },
        ]"
      >
        <a-icon slot="prefix" type="mobile" />

        <a-button
          slot="enterButton"
          type="primary"
          :disabled="getSmsBut.disabled"
          style="width: 112px"
        >
          {{ getSmsBut.text }}
        </a-button>
      </a-input>
    </a-form-item>
    <a-form-item>
      <a-input-search
        size="large"
        @search="getRegistrationSms"
        placeholder="请输入短信验证码"
        v-decorator="[
          'verificationCode',
          { rules: [{ required: true, message: '短信验证码不能为空' }] },
        ]"
      >
        <a-icon slot="prefix" type="message" />

        <a-button
          slot="enterButton"
          type="primary"
          :disabled="getSmsBut.disabled"
          style="width: 112px"
        >
          {{ getSmsBut.text }}
        </a-button>
      </a-input-search>
    </a-form-item>

    <a-form-item>
      <a-input
        size="large"
        placeholder="设置密码"
        v-decorator="[
          'password',
          { rules: [{ required: true, validator: checkPassword }] },
        ]"
      >
        <a-icon slot="prefix" type="lock" />
      </a-input>
    </a-form-item>

    <a-form-item>
      <a-input
        size="large"
        placeholder="确认密码"
        v-decorator="[
          'confirmPassword',
          { rules: [{ required: true, validator: checkConfirmPassword }] },
        ]"
      >
        <a-icon slot="prefix" type="lock" />
      </a-input>
    </a-form-item>

    <!-- <a-form-item>
        <a-checkbox-group
          v-decorator="[
            'agreement',
            { rules: [{ required: true, message: '请阅读协议并同意' }] },
          ]"
        >
          <a-checkbox value="consent" style="user-select: none;color: #ffffff;">
            我已阅读并接受以下条款

            <a-button type="link" style="padding: 0;" @click="clickAgreement">
              《XXXXXXXXXX》
            </a-button>

          </a-checkbox>
        </a-checkbox-group>
      </a-form-item> -->

    <a-form-item>
      <a-button
        type="primary"
        html-type="submit"
        size="large"
        style="width: 100%"
      >
        提交
      </a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import { sign } from "@/api/manage";
import md5 from "js-md5";

export default {
  data() {
    return {
      registrationForm: this.$form.createForm(this),
      getSmsBut: {
        text: "获取验证码",
        disabled: false,
      },
    };
  },
  methods: {
    // 提交注册
    registrationFormSubmit(e) {
      const that = this;
      e.preventDefault();
      this.registrationForm.validateFields((err, values) => {
        if (!err) {
          const password = md5(values.password);
          sign({
            phone: values.phone,
            code: values.verificationCode,
            password: password,
          }).then((res) => {
            if (res.code == 200) {
              localStorage.setItem("Access-Token", res.data.token);

              that.$notification.open({
                message: "温馨提示",
                description: "注册成功",
                icon: (
                  <a-icon
                    type="check-circle"
                    theme="twoTone"
                    two-tone-color="#52c41a"
                  />
                ),
              });

              this.$router.push({
                path: "/authentication",
              });
            }
          });
        }
      });
    },
    /**
     * 验证手机号码
     */
    checkPhoneNumber(rule, value, callback) {
      return this.$parent.checkPhoneNumber(rule, value, callback);
    },
    // 注册获取短信验证码
    getRegistrationSms() {
      this.registrationForm.validateFields(["phone"], (err) => {
        if (!err) {
          const phone = this.registrationForm.getFieldValue("phone");
          this.$parent.getSms(phone, "sign", this.getSmsBut);
        }
      });
    },
    /**
     * 验证密码
     */
    checkPassword(rule, value, callback) {
      return this.$parent.checkPassword(rule, value, callback);
    },
    /**
     * 二次输入密码验证
     */
    checkConfirmPassword(rule, value, callback) {
      const password = this.registrationForm.getFieldValue("password");

      return this.$parent.checkConfirmPassword(rule, value, password, callback);
    },
  },
};
</script>

<style>
</style>