<template>
  <div style="width: 100%">
    <a-tabs
      :activeKey="customActiveKey"
      :tabBarStyle="{
        textAlign: 'center',
        borderBottom: 'unset',
        color: '#ffffff',
      }"
      @change="tabClick"
      style="width: 100%"
    >
      <a-tab-pane
        key="password"
        tab="密码登录"
        style="display: flex; justify-content: center"
      >
        <a-form
          id="passwordLoginForm"
          ref="passwordLoginForm"
          :form="passwordLoginForm"
          @submit="passwordLoginFormSubmit"
          style="width: 50%"
        >
          <a-form-item>
            <a-input
              size="large"
              placeholder="请输入登录账号"
              v-decorator="[
                'account',
                { rules: [{ required: true, validator: checkPhoneNumber }] },
              ]"
              style="margin: 5px 0"
            >
              <a-icon slot="prefix" type="user" />
            </a-input>
          </a-form-item>

          <a-form-item>
            <a-input-password
              size="large"
              placeholder="请输入登录密码"
              v-decorator="[
                'password',
                { rules: [{ required: true, message: '登录密码不能为空' }] },
              ]"
              style="margin: 5px 0"
            >
              <a-icon slot="prefix" type="lock" />
            </a-input-password>
          </a-form-item>
          <a-form-item style="margin-bottom: 0">
            <a-button
              type="primary"
              html-type="submit"
              size="large"
              style="width: 100%"
            >
              登录
            </a-button>
          </a-form-item>
        </a-form>
      </a-tab-pane>

      <a-tab-pane
        key="sms"
        tab="短信登录"
        style="display: flex; justify-content: center"
      >
        <a-form
          id="smsLoginForm"
          ref="smsLoginForm"
          :form="smsLoginForm"
          @submit="smsLoginFormSubmit"
          style="width: 50%"
        >
          <a-form-item>
            <a-input
              size="large"
              placeholder="请输入手机号"
              v-decorator="[
                'phone',
                { rules: [{ required: true, validator: checkPhoneNumber }] },
              ]"
              style="margin: 5px 0"
            >
              <a-icon slot="prefix" type="user" />
            </a-input>
          </a-form-item>

          <a-form-item>
            <a-input-search
              size="large"
              @search="getLoginSms"
              placeholder="请输入短信验证码"
              v-decorator="[
                'verificationCode',
                { rules: [{ required: true, message: '短信验证码不能为空' }] },
              ]"
            >
              <a-icon slot="prefix" type="message" />

              <a-button
                slot="enterButton"
                type="primary"
                :disabled="getSmsBut.disabled"
                style="width: 112px"
              >
                {{ getSmsBut.text }}
              </a-button>
            </a-input-search>
          </a-form-item>
          <a-form-item style="margin-bottom: 0">
            <a-button
              type="primary"
              html-type="submit"
              size="large"
              style="width: 100%"
            >
              登录
            </a-button>
          </a-form-item>
        </a-form>
      </a-tab-pane>
    </a-tabs>

    <a-row
      type="flex"
      style="width: 50%; justify-content: space-between; margin: auto"
    >
      <a-button
        type="link"
        style="color: #ffc000"
        size="large"
        @click="clickRegister"
      >
        注册账号
      </a-button>

      <a-button
        type="link"
        style="color: #ffc000"
        size="large"
        @click="clickForget"
      >
        忘记密码
      </a-button>
    </a-row>
  </div>
</template>

<script>
import { login } from "@/api/manage";
import md5 from "js-md5";
export default {
  data() {
    return {
      customActiveKey: "password",
      passwordLoginForm: this.$form.createForm(this),
      smsLoginForm: this.$form.createForm(this),
      getSmsBut: {
        text: "获取验证码",
        disabled: false,
      },
    };
  },
  methods: {
    tabClick(key) {
      this.customActiveKey = key;
    },
    // 点击注册
    clickRegister() {
      this.$parent.loginModule = 2;
    },
    // 点击忘记密码
    clickForget() {
      this.$parent.loginModule = 3;
    },
    // 账号密码登录
    passwordLoginFormSubmit(e) {
      e.preventDefault();
      this.passwordLoginForm.validateFields((err, values) => {
        if (!err) {
          console.log(values);
          let param = {
            type: 1,
            phone: values.account,
            password: md5(values.password),
          };
          this.login(param);
        }
      });
    },
    // 登录
    login(param) {
      login(param).then((res) => {
        console.log(res);
        if (res.code == 200) {
          localStorage.setItem("Access-Token", res.data.token);

          this.$router.push({
            path: "/authentication",
          });
        }
      });
    },
    // 登录获取短信验证码
    getLoginSms() {
      this.smsLoginForm.validateFields(["phone"], (err) => {
        if (!err) {
          const phone = this.smsLoginForm.getFieldValue("phone");
          this.$parent.getSms(phone, "login", this.getSmsBut);
        }
      });
    },
    // 短信登录
    smsLoginFormSubmit(e) {
      e.preventDefault();
      this.smsLoginForm.validateFields((err, values) => {
        if (!err) {
          console.log(values);
          let param = {
            type: 2,
            phone: values.phone,
            code: values.verificationCode,
          };
          this.login(param);
        }
      });
    },
    /**
     * 验证手机号码
     */
    checkPhoneNumber(rule, value, callback) {
      return this.$parent.checkPhoneNumber(rule, value, callback);
    },
  },
};
</script>

<style>
</style>