const qiniu = require('qiniu-js');

import { getQiniuUploadInfo } from "@/api/manage"

export default function (filesList, callback) {
  
  // console.log(filesList);

  let count = 0;

  let imgList = new Array;

  // 七牛云上传配置
  let config = {
    //表示是否使用 cdn 加速域名
    useCdnDomain: true,
    //qiniu.region.z0: 代表华东区域
    // qiniu.region.z1: 代表华北区域
    // qiniu.region.z2: 代表华南区域
    // qiniu.region.na0: 代表北美区域
    // qiniu.region.as0: 代表东南亚区域
    region: qiniu.region.z2
    //是否禁用日志报告
    // disableStatisticsReport: false
  };

  let putExtra = {
    //文件原文件名
    fname: "",
    // 用来放置自定义变量
    params: {},
    // 用来限制上传文件类型 ,限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
    mimeType: [] || null
  };

  return new Promise((resolve, reject) => {

    function recursive() {

      getQiniuUploadInfo({
        path: "authentication/business"
      }).then(qiniuTokenInfo => {

        if(qiniuTokenInfo.code != 200 || qiniuTokenInfo.data.result != 1) {
          reject("获取七牛token错误");
        }

        // 添加上传信息
        const observable = qiniu.upload(filesList[count].originFileObj, qiniuTokenInfo.data.key, qiniuTokenInfo.data.token, putExtra, config);

        // 上传开始
        const subscription = observable.subscribe(res => {

          // 接收上传进度信息的回调函数
          // console.log("上传进度: ", res);
          callback(res);

        }, err => {

          // 上传错误后触发
          console.log("上传错误: ", err);
          reject(err);


        }, res => {

          imgList.push(qiniuTokenInfo.data.download_url);

          // 接收上传完成后的后端返回信息
          console.log("上传完成: ", res);

          count++;
          if (count < filesList.length) {

            recursive();

          } else {

            resolve(imgList);

          }
        })
      });
    }
    recursive();
  });
}

