<template>
  <div class="BoothEditorExplain">

    <div class="title">
      中国-东盟智慧城市体验展商家编辑器
    </div>

    <div class="bg-img">

      <img src="/bianjiqi.png" >

      <img src="/BG1.jpg" style="width: 100%;position: absolute;top: 0;left:0;right: 0;bottom: 0;margin: auto;z-index: -1;">

    </div>

    <div class="account-management btn" @click="clickAccountManagement">
      账号管理
    </div>

    <div class="editor btn">
      <!-- 点击进入 -->
      即将开放使用，敬请期待......
    </div>

    <div class="await btn" @click="clickDownload">
      <!-- 即将开放下载，敬请期待...... -->
      点击下载
    </div>

    <img src="/bianjiqi-5.png" style="position: absolute;top: 815px;left: 915px;" @click="clickTutorial('document')">

    <img src="/bianjiqi-6.png" style="position: absolute;top: 870px;left: 915px;" @click="clickTutorial('video')">


  </div>
</template>

<script>
// geteditorurl

import { geteditorurl } from "@/api/manage";

export default {
  data() {
    return {
      downloadURL: ""
    }
  },
  created() {
    geteditorurl().then(res => {

      this.downloadURL = res;

    })
  },
  methods: {
    clickAccountManagement() {
      this.$router.push({
        path: "/registration"
      });
    },
    clickTutorial(type) {

      if(type == "document") {

        window.location.href = "https://metares.jx3d.cn/tutorial/%E5%B1%95%E4%BD%8D%E5%BA%97%E9%93%BA%E7%BC%96%E8%BE%91%E5%99%A8%E4%BD%BF%E7%94%A8%E6%95%99%E7%A8%8B.pdf";

      } else if(type == "video") {

        window.location.href = "https://metares.jx3d.cn/tutorial/%E5%B1%95%E4%BD%8D%E5%BA%97%E9%93%BA%E7%BC%96%E8%BE%91%E5%99%A8%E4%BD%BF%E7%94%A8%E6%95%99%E7%A8%8B.mp4";

      }
    },
    clickDownload() {
      window.location.href = this.downloadURL;
    }
  }

}
</script>

<style scoped>


.BoothEditorExplain {
  /* min-width: 100%;
  min-height: 100%; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* position: relative; */
  width: 1920px;
  position: absolute;
  margin: auto;
  /* background: url("/public/BG1.jpg") no-repeat; */
  /* background-attachment: fixed; */

}

.bg-img {
  pointer-events: none;
}

/* .BoothEditorExplain img {
  width: 90%;
} */

.title {
  /* width: 556px; */
  width: 42%;
  height: 70px;
  /* position: absolute; */
  /* top: 0; */
  margin: 0 auto 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: #94a5f7; */
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  user-select: none;
  background: url("/public/bjq-name-board.png") no-repeat;
  background-size: 100% 100%;
}

.btn {
  cursor:pointer;
  color: #2d3248;
  position: absolute;
  font-weight: bold;
  font-size: 20px;
  /* font-size: 1rem; */
  background-color: #ffc000;
}

.account-management {
  top: 30px;
  right: 40px;
  padding: 5px 50px;
  border-radius: 25px;
}

.editor {
  top: 450px;
  right: 55px;
  /* padding: 3px 40px;
  border-radius: 10px; */
  color: #ffffff;
  background-color: transparent;
}

.await {
  top: 870px;
  right: 55px;
  padding: 3px 40px;
  border-radius: 10px;
  /* color: #ffffff;
  background-color: transparent; */
}

</style>