<template>
  <div id="authentication">


    <div class="content">

      <div class="title">
        
        {{ title }}

      </div>


      <a-button class="close" type="link" size="large" style="font-size: 18px;color: #ffffff;" v-if="authenticationType == 'unselected'" @click="loginOut">
        退出登录
      </a-button>
      
      <img class="close" src="/bjq-27.png" v-if="authenticationType != 'unselected'" @click="clickClose" >

      <template v-if="authenticationType === 'unselected'">

        <div class="select-authentication-type" @click="clickBusiness('booth')">
          <div class="business">

            <!-- <img src="/qiye.png" @click="clickBusiness" /> -->
              <!-- 企业认证 -->
            <img src="/bjq-renzheng-03.png"  />

            <template v-if="userInfo.state == 0">

             <img src="/bjq-renzheng-05.png"/>

            </template>

            <template v-else-if="userInfo.state == 1">

              <img src="/bjq-renzheng-07.png"/>

            </template>

            <template v-else-if="userInfo.state == 2">

              <img src="/bjq-renzheng-06.png"/>

            </template>

            <template v-else-if="userInfo.state == 3">

              <img src="/bjq-renzheng-08.png"/>

            </template>
          
          </div>
          

          <div class="personal" @click="clickBusiness('shop')">

            <!-- <img src="/geren.png" @click="clickPersonal"/> -->
            <!-- 个人认证 -->
            <img src="/bjq-renzheng-04.png" />

            <!-- <img src="/bjq-renzheng-05.png"/> -->

            <template v-if="userInfo.shop_state == 0">

             <img src="/bjq-renzheng-05.png"/>

            </template>

            <template v-else-if="userInfo.shop_state == 1">

              <img src="/bjq-renzheng-07.png"/>

            </template>

            <template v-else-if="userInfo.shop_state == 2">

              <img src="/bjq-renzheng-06.png"/>

            </template>

            <template v-else-if="userInfo.shop_state == 3">

              <img src="/bjq-renzheng-08.png"/>

            </template>

          </div>
        </div>

      </template>

      <template v-else-if="authenticationType === 'business'">

        <div class="business-from">

          <enterprise-certification-from :authenticationFormData="authenticationFormData" :showType="showType"></enterprise-certification-from>

        </div>

      </template>

      <template v-else-if="authenticationType === 'personal'">

        <div class="personal-from">

          <personal-certificate-from></personal-certificate-from>

        </div>

      </template>


    </div>
  </div>
</template>

<script>

import EnterpriseCertificationFrom from "@/components/EnterpriseCertificationFrom.vue"

import PersonalCertificateFrom from "@/components/PersonalCertificateFrom.vue"

import { getuserinfo } from "@/api/manage";
import storage from "@/store"

export default {
  components: {
    EnterpriseCertificationFrom,
    PersonalCertificateFrom
  },
  data() {
    return {
      authenticationType: "unselected",
      title: "选择认证方式",
      // authenticationType: "business",
      // title: "企业认证",
      userInfo: {},
      authenticationFormData: {},
      showType: 1
    };
  },
  created() {

    this.initData();
    
  },
  methods: {
    initData() {

      // let geturl = window.location.href
      // let getqyinfo = geturl.split('?');
      // let token;
      // if(getqyinfo.length > 1) {
      //   getqyinfo = getqyinfo[1];
      //   token = getqyinfo.split('&')[0].split('=')[1];
      //   console.log(token);
      //   localStorage.setItem("Access-Token", token);
        
      // } else {

      //   token = localStorage.getItem("Access-Token");

      // }

      let token = localStorage.getItem("Access-Token");
      
      if(token) {
        getuserinfo({
          cmd: "get"
        }).then(res => {
          console.log(res);
          if(res.code == 200) {
            this.userInfo = res.data;
            this.authenticationFormData = res.data;
          }
        });
      }

    },
    loginOut() {
      localStorage.removeItem("Access-Token");
      this.$router.push({
        path: "/registration"
      });
    },
    /**
     * 点击企业认证
     */
    clickBusiness(showType) {

      
      this.authenticationType = "business";
      // this.title = "企业认证";
      // this.title = "认证审核";


      // this.authenticationFormData = this.userInfo;


      if(showType == "booth") {
        this.title = "展位认证审核";
        this.showType = 1;

      } else if(showType == "shop") {
        this.title = "店铺认证审核";
        this.showType = 2;

      }

    },
    /**
     * 点击个人认证
     */
    clickPersonal() {

      this.authenticationType = "personal";
      this.title = "个人认证";

    },
    clickClose() {
      console.log("123");
      this.authenticationType = "unselected";
      this.title = "选择认证方式";
    }
  },
};
</script>

<style scoped>

#authentication {
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("/public/BG1.jpg") 100% 100% no-repeat;
}

.content {
  width: 69%;
  min-height: 715px;
  margin: 30px 0;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background: url("/public/denglu.png") no-repeat;
  background-size: 100% 100%;
}

.title {
  /* width: 556px; */
  width: 42%;
  height: 57px;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: #94a5f7; */
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  user-select: none;
  background: url("/public/bjq-name-board.png") no-repeat;
  background-size: 100% 100%;
}

.close {
  
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;

}

.select-authentication-type {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  user-select:none;
  }

.business,
.personal {

  display: flex;
  flex-direction: column;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;

}

.business img,
.personal img {
  margin-bottom: 20px;

}

.business-from,
.personal-from {
  width: 100%;
  height: 100%;
  padding-top: 70px;
  padding-bottom: 30px;
}

::v-deep .ant-upload.ant-upload-select-picture-card {
  background-color: transparent;
  border: none;
  border-radius: 0;
}

::v-deep .ant-upload-list-picture-card .ant-upload-list-item {
  width: 302px;
  height: 166px;
}

::v-deep .ant-upload-picture-card-wrapper {
  height: 190px;
}

::v-deep .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  object-fit: contain;
}

::v-deep .ant-upload-list-picture-card .ant-upload-list-item {
  margin: 8px;
}

.select-authentication-type img {
  pointer-events: none;
}

</style>