var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"PersonalCertificateFrom"}},[_c('a-form',{attrs:{"form":_vm.PersonalCertificateFrom,"label-col":{ span: 6 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.PersonalCertificateFromSubmit}},[_c('a-form-item',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"label-name"},[_vm._v("姓名")])]},proxy:true}])},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'userName',
          { rules: [{ required: true, message: '请输入姓名' }] },
        ]),expression:"[\n          'userName',\n          { rules: [{ required: true, message: '请输入姓名' }] },\n        ]"}],attrs:{"placeholder":"请输入姓名"}})],1),_c('a-form-item',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"label-name"},[_vm._v("地址")])]},proxy:true}])},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'address',
          { rules: [{ required: true, message: '请输入地址' }] },
        ]),expression:"[\n          'address',\n          { rules: [{ required: true, message: '请输入地址' }] },\n        ]"}],attrs:{"placeholder":"请输入地址"}})],1),_c('a-form-item',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"label-name"},[_vm._v("身份证号")])]},proxy:true}])},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'IDNumber',
          { rules: [{ required: true, validator: _vm.checkIDNumber }] },
        ]),expression:"[\n          'IDNumber',\n          { rules: [{ required: true, validator: checkIDNumber }] },\n        ]"}],attrs:{"placeholder":"请输入身份证号"}})],1),_c('a-form-item',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"label-name"},[_vm._v("联系电话")])]},proxy:true}])},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'phoneNumber',
          { rules: [{ required: true, validator: _vm.checkPhoneNumber }] },
        ]),expression:"[\n          'phoneNumber',\n          { rules: [{ required: true, validator: checkPhoneNumber }] },\n        ]"}],attrs:{"placeholder":"请输入联系电话"}})],1),_c('a-form-item',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"label-name"},[_vm._v("邮箱")])]},proxy:true}])},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'EMail',
          { rules: [{ required: true, validator: _vm.checkEMail }] },
        ]),expression:"[\n          'EMail',\n          { rules: [{ required: true, validator: checkEMail }] },\n        ]"}],attrs:{"placeholder":"请输入邮箱"}})],1),_c('a-form-item',{staticStyle:{"color":"red"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"label-name"},[_vm._v("身份证")])]},proxy:true}])},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"xxl":{span: 12},"xl":{span: 24}}},[_c('a-form-item',[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'identityCardFront',
                {
                  rules: [{ required: true, message: '请上传身份证' }],
                  initialValue: _vm.identityCardFrontFileList,
                },
              ]),expression:"[\n                'identityCardFront',\n                {\n                  rules: [{ required: true, message: '请上传身份证' }],\n                  initialValue: identityCardFrontFileList,\n                },\n              ]"}],attrs:{"list-type":"picture-card","remove":_vm.removeIdentityCardFront,"beforeUpload":_vm.identityCardFrontUpload},on:{"preview":function($event){_vm.identityCardPreviewVisible = true}}},[(_vm.identityCardFrontFileList.length < 1)?[_c('img',{attrs:{"src":"/bjq-add-02.png"}}),_c('div',{staticClass:"identity-card-hint"},[_vm._v(" 身份证正面照片 (含个人信息的一面) ")])]:_vm._e()],2)],1)],1),_c('a-col',{attrs:{"xxl":{span: 12},"xl":{span: 24}}},[_c('a-form-item',[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'identityCardReverseSide',
                {
                  rules: [{ required: true, message: '请上传身份证' }],
                  initialValue: _vm.identityCardReverseSideFileList,
                },
              ]),expression:"[\n                'identityCardReverseSide',\n                {\n                  rules: [{ required: true, message: '请上传身份证' }],\n                  initialValue: identityCardReverseSideFileList,\n                },\n              ]"}],attrs:{"list-type":"picture-card","remove":_vm.removeIdentityCardReverseSide,"beforeUpload":_vm.identityCardReverseSideUpload},on:{"preview":function($event){_vm.identityCardPreviewVisible = true}}},[(_vm.identityCardReverseSideFileList.length < 1)?[_c('img',{attrs:{"src":"/bjq-add-02.png"}}),_c('div',{staticClass:"identity-card-hint"},[_vm._v(" 身份证反面照片 (含发证机关的一面) ")])]:_vm._e()],2)],1)],1)],1),_c('a-modal',{attrs:{"visible":_vm.identityCardPreviewVisible,"footer":null},on:{"cancel":function($event){_vm.identityCardPreviewVisible = false}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.identityCardPreviewBase64}})])],1),_c('a-button',{attrs:{"type":"primary","html-type":"submit","size":"large"}},[_vm._v(" 提交 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }