import router from './router'

// 不用登录验证的页面名单
const allowList = ["", "login"];


router.beforeEach((to, from, next) => {

  // console.log(to);
  // console.log(from);
  // console.log(next);

  let geturl = window.location.href
  let getqyinfo = geturl.split('?');
  let token;
  if(getqyinfo.length > 1) {

    getqyinfo = getqyinfo[1];

    token = getqyinfo.split('&')[0].split('=')[1];

    localStorage.setItem("Access-Token", token);

  } else {

    token = localStorage.getItem("Access-Token");

  }

  if(to.path == "/authentication") {

    // let token = localStorage.getItem("Access-Token");

    if(token) {
      next();
    } else {
      next({path: "/registration"});
    }

  } else {
    next();
  }

});