const shajs = require("sha.js");
export function createSign(data) {

  let SecretKey = "Ps6CnU2rwBWBC36DCJI4fazE4N4JowBo";

  let getAppendStr = (o) => {
    if (Object.prototype.toString.call(o) == '[object Array]' || Object.prototype.toString.call(o) == '[object Object]') {
      return JSON.stringify(o);
    } else {
      return o;
    }
  }

  let datastr =
    Object.keys(data)
    .sort()
    .reduce((prev, cur) => {
      prev += `${cur}=${getAppendStr(data[cur])}&`
      return prev
    }, '') + `SecretKey=${SecretKey}`;
  return shajs("sha256").update(datastr).digest("hex").toUpperCase();
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  });
}