import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
// import Login from "@/views/login/Login.vue"
// import Unity from "@/views/unityWebgl/UnityWebgl.vue"
import Registration from "@/views/registration/Registration"
import Authentication from "@/views/authentication/Authentication"
import BoothEditorExplain from "@/views/boothEditorExplain/BoothEditorExplain"

Vue.use(VueRouter)

const routes = [
  // {
  //   path: "/login",
  //   name: "login",
  //   component: Login
  // },
  // {
  //   path: "/unity",
  //   name: "unity",
  //   component: Unity
  // },
  // boothEditorExplain
  {
    path: "/",
    name: "/",
    component: BoothEditorExplain
  },
  {
    path: "/registration",
    name: "registration",
    component: Registration
  },
  {
    path: "/authentication",
    name: "authentication",
    component: Authentication
  }
  
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
